<template>
    <div v-if="dailyReports && person" class="container" style="width: 95%">
        <div class="text-right mb-2 no_print">
            <button class="btn btn-info" @click="printReport">印刷</button>
        </div>
        <div class="p-3 background-white">
            <div class="print_pages">
                <div class="row">
                    <div class="col-6">
                        <h3 class="text-left font-weight-bold">ヘルスチェックレポート</h3>
                        <div class="border-bottom pt-3">
                    <span class="m-0">施設名：{{
                            person.relationships.facility.attributes.name ? person.relationships.facility.attributes.name + ' 御中' : ''
                        }}</span>
                        </div>
                        <div class="border-bottom pt-3">
                            <span class="m-0">氏名：</span>
                            <span class="m-0 px-3">{{ person.attributes.name ? person.attributes.name + ' 様' : '' }}</span>
                        </div>
                        <div class="pt-3">
                            <p class="text-dark m-0">この報告は、日々の問診により得た情報を集約したものです。</p>
                            <p class="text-dark m-0">ご自身の体調管理や、支援にご活用ください。</p>
                        </div>
                    </div>
                    <div class="col-2">
                    </div>
                    <div class="col-4">
                        <div class="pt-3">
                            <p class="text-dark m-0 pt-2">{{
                                    person.relationships.facility.relationships.office.attributes.address ? person.relationships.facility.relationships.office.attributes.address : ''
                                }}</p>
                            <p class="text-dark m-0 pt-2">{{
                                    person.relationships.facility.relationships.office.attributes.company_name ? person.relationships.facility.relationships.office.attributes.company_name : ''
                                }}</p>
                            <p class="text-dark m-0 pt-2">{{
                                    person.relationships.facility.relationships.office.attributes.name ? person.relationships.facility.relationships.office.attributes.name : ''
                                }}</p>
                            <p class="text-dark m-0 pt-2">{{
                                    person.relationships.facility.relationships.office.attributes.tel ? 'TEL：' + person.relationships.facility.relationships.office.attributes.tel : ''
                                }}</p>
                        </div>
                    </div>
                </div>
                <template v-if="render">
                    <div class="row pt-2">
                        <div class="col-6 mx-auto text-center p-0 m-0">
                            <p class="p-0 m-0">{{ this.$moment(this.$route.query.date).format('YYYY年MM月') }}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <chart class="chart" :data="pressureData" :options="pressureOptions"></chart>
                        </div>
                        <div class="col-6">
                            <chart class="chart" :data="pulseData" :options="pulseOptions"></chart>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-6">
                            <chart class="chart" :data="daytimeData" :options="daytimeOptions"></chart>
                        </div>
                        <div class="col-6">
                            <chart class="chart" :data="humidityData" :options="humidityOptions"></chart>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-6">
                            <chart class="chart" :data="hpaData" :options="hpaOptions"></chart>
                        </div>
                        <div class="col-6">
                            <chart class="chart" :data="temperatureData" :options="temperatureOptions"></chart>
                        </div>
                    </div>
                </template>
            </div>
            <div class="row pt-5 print-pt">
                <div class="col-12">
                    <template v-if="dailyReports.length > 0">
                        <table class="table table-bordered" style="font-size: 15px">
                            <thead>
                            <tr>
                                <th rowspan="3" colspan="7" class="nowrap p-0 m-0 py-1 text-center">
                                    {{
                                        $moment($route.query.date).format('M月') ? $moment($route.query.date).format('M月') : ''
                                    }}
                                </th>
                                <th colspan="72" class="p-0 m-0 py-1 text-center">睡眠(ご利用者様の主観的な情報)</th>
                                <th colspan="18" class="p-0 m-0 py-1 text-center">生活リズム</th>
                            </tr>
                            <tr>
                                <th colspan="60" class="p-0 m-0 py-1 text-center">時間</th>
                                <th rowspan="2" colspan="6" class="p-0 m-0 py-1 text-center">量</th>
                                <th rowspan="2" colspan="6" class="p-0 m-0 py-1 text-center">質</th>
                                <th colspan="4" class="nowrap p-0 m-0 py-1 text-center">気分</th>
                                <th colspan="4" class="p-0 m-0 py-1 text-center">食事</th>
                                <th colspan="4" class="p-0 m-0 py-1 text-center">薬</th>
                                <th colspan="6" class="nowrap p-0 m-0 py-1 text-center">天気</th>
                            </tr>
                            <tr>
                                <th v-for="(time,index) in sleepTime" :key="index" colspan="3"
                                    class="nowrap p-0 m-0 py-1 text-center">{{ time }}
                                </th>
                                <th colspan="4" class="nowrap p-0 m-0 py-1 text-center">合計</th>
                                <th colspan="4" class="p-0 m-0 py-1 text-center">○×△</th>
                                <th colspan="4" class="p-0 m-0 py-1 text-center">○×</th>
                                <th colspan="6" class="p-0 m-0 py-1 text-center"></th>
                            </tr>
                            </thead>
                            <tbody>
                            <template v-for="(dailyReport,index) in dailyReports">
                                <tr :key="index">
                                    <td colspan="7" class="nowrap p-0 m-0 py-1 text-center">
                                        {{ displayDate(dailyReport.attributes.register_date) }}
                                    </td>
                                    <td colspan="3"
                                        :class="[{'background': checkDateTime(dailyReport.attributes.bed_time,dailyReport.attributes.wake_time,dailyReport.attributes.register_date,'18',1)},'p-0 m-0']"></td>
                                    <td colspan="3"
                                        :class="[{'background': checkDateTime(dailyReport.attributes.bed_time,dailyReport.attributes.wake_time,dailyReport.attributes.register_date,'19',1)},'p-0 m-0']"></td>
                                    <td colspan="3"
                                        :class="[{'background': checkDateTime(dailyReport.attributes.bed_time,dailyReport.attributes.wake_time,dailyReport.attributes.register_date,'20',1)},'p-0 m-0']"></td>
                                    <td colspan="3"
                                        :class="[{'background': checkDateTime(dailyReport.attributes.bed_time,dailyReport.attributes.wake_time,dailyReport.attributes.register_date,'21',1)},'p-0 m-0']"></td>
                                    <td colspan="3"
                                        :class="[{'background': checkDateTime(dailyReport.attributes.bed_time,dailyReport.attributes.wake_time,dailyReport.attributes.register_date,'22',1)},'p-0 m-0']"></td>
                                    <td colspan="3"
                                        :class="[{'background': checkDateTime(dailyReport.attributes.bed_time,dailyReport.attributes.wake_time,dailyReport.attributes.register_date,'23',1)},'p-0 m-0']"></td>
                                    <td colspan="3"
                                        :class="[{'background': checkDateTime(dailyReport.attributes.bed_time,dailyReport.attributes.wake_time,dailyReport.attributes.register_date,'00',0)},'p-0 m-0']"></td>
                                    <td colspan="3"
                                        :class="[{'background': checkDateTime(dailyReport.attributes.bed_time,dailyReport.attributes.wake_time,dailyReport.attributes.register_date,'01',0)},'p-0 m-0']"></td>
                                    <td colspan="3"
                                        :class="[{'background': checkDateTime(dailyReport.attributes.bed_time,dailyReport.attributes.wake_time,dailyReport.attributes.register_date,'02',0)},'p-0 m-0']"></td>
                                    <td colspan="3"
                                        :class="[{'background': checkDateTime(dailyReport.attributes.bed_time,dailyReport.attributes.wake_time,dailyReport.attributes.register_date,'03',0)},'p-0 m-0']"></td>
                                    <td colspan="3"
                                        :class="[{'background': checkDateTime(dailyReport.attributes.bed_time,dailyReport.attributes.wake_time,dailyReport.attributes.register_date,'04',0)},'p-0 m-0']"></td>
                                    <td colspan="3"
                                        :class="[{'background': checkDateTime(dailyReport.attributes.bed_time,dailyReport.attributes.wake_time,dailyReport.attributes.register_date,'05',0)},'p-0 m-0']"></td>
                                    <td colspan="3"
                                        :class="[{'background': checkDateTime(dailyReport.attributes.bed_time,dailyReport.attributes.wake_time,dailyReport.attributes.register_date,'06',0)},'p-0 m-0']"></td>
                                    <td colspan="3"
                                        :class="[{'background': checkDateTime(dailyReport.attributes.bed_time,dailyReport.attributes.wake_time,dailyReport.attributes.register_date,'07',0)},'p-0 m-0']"></td>
                                    <td colspan="3"
                                        :class="[{'background': checkDateTime(dailyReport.attributes.bed_time,dailyReport.attributes.wake_time,dailyReport.attributes.register_date,'08',0)},'p-0 m-0']"></td>
                                    <td colspan="3"
                                        :class="[{'background': checkDateTime(dailyReport.attributes.bed_time,dailyReport.attributes.wake_time,dailyReport.attributes.register_date,'09',0)},'p-0 m-0']"></td>
                                    <td colspan="3"
                                        :class="[{'background': checkDateTime(dailyReport.attributes.bed_time,dailyReport.attributes.wake_time,dailyReport.attributes.register_date,'10',0)},'p-0 m-0']"></td>
                                    <td colspan="3"
                                        :class="[{'background': checkDateTime(dailyReport.attributes.bed_time,dailyReport.attributes.wake_time,dailyReport.attributes.register_date,'11',0)},'p-0 m-0']"></td>
                                    <td colspan="3"
                                        :class="[{'background': checkDateTime(dailyReport.attributes.bed_time,dailyReport.attributes.wake_time,dailyReport.attributes.register_date,'12',0)},'p-0 m-0']"></td>
                                    <td colspan="3"
                                        :class="[{'background': checkDateTime(dailyReport.attributes.bed_time,dailyReport.attributes.wake_time,dailyReport.attributes.register_date,'13',0)},'p-0 m-0']"></td>
                                    <td colspan="6" class="p-0 m-0 text-center py-1">{{ sleepAmount(dailyReport) }}</td>
                                    <td colspan="6" class="p-0 m-0 text-center py-1">
                                        {{
                                            sleepQuality(dailyReport.relationships.dailyReportDetails[2][0].attributes.index)
                                        }}
                                    </td>
                                    <td colspan="4" class="p-0 m-0 text-center py-1">
                                        {{ sumFeeling(dailyReport.relationships.dailyReportDetails) }}
                                    </td>
                                    <td colspan="4" class="p-0 m-0 text-center py-1">
                                        {{ diet(dailyReport.relationships.dailyReportDetails[3][0].attributes.index) }}
                                    </td>
                                    <td colspan="4" class="p-0 m-0 text-center py-1">
                                        {{
                                            medicine(dailyReport.relationships.dailyReportDetails[4][0].attributes.index)
                                        }}
                                    </td>
                                    <td colspan="6" class="p-0 m-0 text-center py-1" style="font-size: 12px">{{
                                            dailyReport.attributes.weather
                                        }}
                                    </td>
                                </tr>
                            </template>
                            </tbody>
                        </table>
                    </template>
                    <template v-else>
                        <table class="table table-bordered" style="font-size: 15px">
                            <thead>
                            <tr>
                                <th rowspan="3" colspan="7" class="nowrap p-0 m-0 py-1 text-center">
                                    {{
                                        $moment($route.query.date).format('M月') ? $moment($route.query.date).format('M月') : ''
                                    }}
                                </th>
                                <th colspan="72" class="p-0 m-0 py-1 text-center">睡眠(ご利用者様の主観的な情報)</th>
                                <th colspan="18" class="p-0 m-0 py-1 text-center">生活リズム</th>
                            </tr>
                            <tr>
                                <th colspan="60" class="p-0 m-0 py-1 text-center">時間</th>
                                <th rowspan="2" colspan="6" class="p-0 m-0 py-1 text-center">量</th>
                                <th rowspan="2" colspan="6" class="p-0 m-0 py-1 text-center">質</th>
                                <th colspan="4" class="nowrap p-0 m-0 py-1 text-center">気分</th>
                                <th colspan="4" class="p-0 m-0 py-1 text-center">食事</th>
                                <th colspan="4" class="p-0 m-0 py-1 text-center">薬</th>
                                <th colspan="6" class="nowrap p-0 m-0 py-1 text-center">天気</th>
                            </tr>
                            <tr>
                                <th v-for="(time,index) in sleepTime" :key="index" colspan="3"
                                    class="nowrap p-0 m-0 py-1 text-center">{{ time }}
                                </th>
                                <th colspan="4" class="nowrap p-0 m-0 py-1 text-center">合計</th>
                                <th colspan="4" class="p-0 m-0 py-1 text-center">○×△</th>
                                <th colspan="4" class="p-0 m-0 py-1 text-center">○×</th>
                                <th colspan="6" class="p-0 m-0 py-1 text-center"></th>
                            </tr>
                            </thead>
                        </table>
                        <h5 class="mx-auto text-center pb-2">
                            訪問はありません
                        </h5>
                    </template>
                </div>
            </div>
            <div class="row pt-5">
                <div class="col-4">
                    <table class="table table-bordered" style="font-size: 15px">
                        <tbody>
                        <tr>
                            <td class="p-0 m-0 py-1 text-center small">平均就寝時間</td>
                            <td class="p-0 m-0 py-1 text-center small">{{
                                    this.aveTime(this.calcTotalMinutes('bed_time')) ? this.aveTime(this.calcTotalMinutes('bed_time')) : ''
                                }}
                            </td>
                        </tr>
                        <tr>
                            <td class="p-0 m-0 py-1 text-center small">平均起床時間</td>
                            <td class="p-0 m-0 py-1 text-center small">{{
                                    this.aveTime(this.calcTotalMinutes('wake_time')) ? this.aveTime(this.calcTotalMinutes('wake_time')) : ''
                                }}
                            </td>
                        </tr>
                        <tr>
                            <td class="p-0 m-0 py-1 text-center small">平均睡眠時間</td>
                            <td class="p-0 m-0 py-1 text-center small">{{ aveTotalSleepTime }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-4">
                    <table class="table table-bordered" style="font-size: 15px">
                        <tbody>
                        <tr>
                            <td class="p-0 m-0 py-1 text-center small">平均最高血圧</td>
                            <td class="p-0 m-0 py-1 text-center small">
                                {{ round(ave('pressure_max'), 1) ? round(ave('pressure_max'), 1) : '' }}
                            </td>
                        </tr>
                        <tr>
                            <td class="p-0 m-0 py-1 text-center small">平均最低血圧</td>
                            <td class="p-0 m-0 py-1 text-center small">
                                {{ round(ave('pressure_min'), 1) ? round(ave('pressure_min'), 1) : '' }}
                            </td>
                        </tr>
                        <tr>
                            <td class="p-0 m-0 py-1 text-center small">平均脈拍</td>
                            <td class="p-0 m-0 py-1 text-center small">
                                {{ round(ave('pulse'), 1) ? round(ave('pulse'), 1) : '' }}
                            </td>
                        </tr>
                        <tr>
                            <td class="p-0 m-0 py-1 text-center small">平均体温</td>
                            <td class="p-0 m-0 py-1 text-center small">{{
                                    round(aveFloat('body_temperature'), 10) ? round(aveFloat('body_temperature'), 10) : ''
                                }}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-4">
                    <table class="table table-bordered" style="font-size: 15px">
                        <tbody>
                        <tr>
                            <td rowspan="3" class="p-0 m-0 py-1 text-center small">食事</td>
                            <td class="p-0 m-0 py-1 text-center small">○</td>
                            <td class="p-0 m-0 py-1 text-center small">食べた</td>
                        </tr>
                        <tr>
                            <td class="p-0 m-0 py-1 text-center small">△</td>
                            <td class="p-0 m-0 py-1 text-center small">不規則</td>
                        </tr>
                        <tr>
                            <td class="p-0 m-0 py-1 text-center small">×</td>
                            <td class="p-0 m-0 py-1 text-center small">食べていない</td>
                        </tr>
                        <tr>
                            <td rowspan="2" class="p-0 m-0 py-1 text-center small">薬</td>
                            <td class="p-0 m-0 py-1 text-center small">○</td>
                            <td class="p-0 m-0 py-1 text-center small">飲めた</td>
                        </tr>
                        <tr>
                            <td class="p-0 m-0 py-1 text-center small">×</td>
                            <td class="p-0 m-0 py-1 text-center small">飲めていない</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row pt-5">
                <div class="col-12">
                    <p class="p-0 m-0">以上、報告とさせて頂きます。ご不明点等がございましたら、ご連絡を頂ければ幸いです。</p>
                    <p class="p-0 m-0">引き続き、どうぞよろしくお願いいたします。</p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Chart from "@/components/Chart/Chart";
import ApiRouter from "@/components/Systems/ApiRouter";
import ErrorHandler from "@/components/Systems/ErrorHandler";

export default {
    name: "DisplayMedicalCooperationNursingReport",
    components: {Chart},
    mixins: [ApiRouter, ErrorHandler],
    created: function () {
        this.createTableDays();
        this.loadDailyReportData();
        this.loadPersonData();
    },
    data: function () {
        return {
            render: false,
            dailyReports: null,
            person: null,
            sleepTime: [
                '18', '19', '20', '21', '22', '23', '00', '01', '02', '03',
                '04', '05', '06', '07', '08', '09', '10', '11', '12', '13'
            ],
            hours: {
                '18': 18, '19': 19, '20': 20, '21': 21,
                '22': 22, '23': 23, '00': 24, '01': 25,
                '02': 26, '03': 27, '04': 28, '05': 29,
                '06': 30, '07': 31, '08': 32, '09': 33,
                '10': 34, '11': 35, '12': 36, '13': 37,

                '14': 38, '15': 39, '16': 40, '17': 41,
            },
            pressureData: {
                labels: [],
                datasets: [
                    {
                        label: '血圧',
                        yAxisID: "y-axis-1",
                        data: [],
                        backgroundColor: "rgba(219,39,91,0.5)"
                    }
                ]
            },
            pressureOptions: {
                title: {
                    display: true,
                },
                scales: {
                    yAxes: [{
                        id: "y-axis-1",
                        position: "left",
                        scaleLabel: {
                            display: true,
                            labelString: '血圧',
                        },
                        ticks: {
                            suggestedMax: 200,
                            suggestedMin: 0,
                            stepSize: 10,
                            callback: function (value, index, values) {
                                return value
                            }
                        }
                    }]
                },
            },
            pulseData: {
                labels: [],
                datasets: [
                    {
                        type: 'line',
                        label: '脈拍',
                        data: [],
                        borderColor: "rgba(255,0,0,1)",
                        backgroundColor: "rgba(0,0,0,0)",
                        yAxisID: "y-axis-1",
                    },
                    {
                        type: 'bar',
                        label: '体温',
                        data: [],
                        borderColor: "rgb(0,255,0)",
                        backgroundColor: "rgb(0,255,0)",
                        yAxisID: "y-axis-2",
                    }
                ],
            },
            pulseOptions: {
                title: {
                    display: true,
                },
                scales: {
                    yAxes: [{
                        id: "y-axis-1",
                        type: "linear",
                        position: "left",
                        scaleLabel: {
                            display: true,
                            labelString: '脈拍',
                        },
                        ticks: {
                            suggestedMax: 140,
                            suggestedMin: 40,
                            stepSize: 10,
                            callback: function (value, index, values) {
                                return value
                            }
                        }
                    },
                        {
                            id: "y-axis-2",
                            type: "linear",
                            position: "right",
                            scaleLabel: {
                                display: true,
                                labelString: '体温',
                            },
                            ticks: {
                                suggestedMax: 40,
                                suggestedMin: 35,
                                stepSize: 1,
                                callback: function (value, index, values) {
                                    return value
                                }
                            }
                        }]
                }
            },
            daytimeData: {
                labels: [],
                datasets: [{
                    yAxisID: "y-axis-1",
                    label: 'スコア',
                    data: [],
                    backgroundColor: 'rgba(0,255,255,0.6)',
                    borderColor: 'rgba(0,255,255,0.5)',
                    borderWidth: 1
                }, {
                    yAxisID: "y-axis-2",
                    type: 'line',
                    label: '日の長さ',
                    data: [],
                    borderColor: "rgba(255,0,255,1)",
                    backgroundColor: "rgba(0,0,0,0)",
                    cubicInterpolationMode: 'undefined'
                }]
            },
            daytimeOptions: {
                title: {
                    display: true,
                },
                scales: {
                    yAxes: [{
                        id: "y-axis-1",
                        position: "left",
                        scaleLabel: {
                            display: true,
                            labelString: 'スコア',
                        },
                        ticks: {
                            suggestedMax: 50,
                            suggestedMin: -50,
                        }
                    }, {
                        id: "y-axis-2",
                        position: "right",
                        ticks: {
                            suggestedMax: 1000,
                            suggestedMin: 500,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '日の長さ',
                        },
                    }]
                }
            },
            humidityData: {
                labels: [],
                datasets: [{
                    yAxisID: "y-axis-1",
                    label: 'スコア',
                    data: [],
                    backgroundColor: 'rgba(0,255,255,0.6)',
                    borderColor: 'rgba(0,255,255,0.5)',
                    borderWidth: 1
                }, {
                    yAxisID: "y-axis-2",
                    type: 'line',
                    label: '湿度',
                    data: [],
                    borderColor: "rgba(0,255,0,1)",
                    backgroundColor: "rgba(0,0,0,0)",
                    cubicInterpolationMode: 'undefined'
                }]
            },
            humidityOptions: {
                title: {
                    display: true,
                },
                scales: {
                    yAxes: [{
                        id: "y-axis-1",
                        position: "left",
                        scaleLabel: {
                            display: true,
                            labelString: 'スコア',
                        },
                        ticks: {
                            suggestedMax: 50,
                            suggestedMin: -50,
                        }
                    }, {
                        id: "y-axis-2",
                        position: "right",
                        ticks: {
                            suggestedMax: 100,
                            suggestedMin: 0,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '湿度(%)',
                        },
                    }]
                }
            },
            hpaData: {
                labels: [],
                datasets: [{
                    yAxisID: "y-axis-1",
                    label: 'スコア',
                    data: [],
                    backgroundColor: 'rgba(0,255,255,0.6)',
                    borderColor: 'rgba(0,255,255,0.5)',
                    borderWidth: 1
                }, {
                    yAxisID: "y-axis-2",
                    type: 'line',
                    label: '気圧(hpa）',
                    data: [],
                    borderColor: "rgba(0,0,255,1)",
                    backgroundColor: "rgba(0,0,0,0)",
                    cubicInterpolationMode: 'undefined'
                }]
            },
            hpaOptions: {
                title: {
                    display: true,
                },
                scales: {
                    yAxes: [{
                        id: "y-axis-1",
                        position: "left",
                        scaleLabel: {
                            display: true,
                            labelString: 'スコア',
                        },
                        ticks: {
                            suggestedMax: 50,
                            suggestedMin: -50,
                        }
                    }, {
                        id: "y-axis-2",
                        position: "right",
                        ticks: {
                            suggestedMax: 1050,
                            suggestedMin: 950,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '気圧(hpa)',
                        },
                    }]
                }
            },
            temperatureData: {
                labels: [],
                datasets: [{
                    yAxisID: "y-axis-1",
                    label: 'スコア',
                    data: [],
                    backgroundColor: 'rgba(0,255,255,0.6)',
                    borderColor: 'rgba(0,255,255,0.5)',
                    borderWidth: 1
                }, {
                    yAxisID: "y-axis-2",
                    label: '気温',
                    data: [],
                    borderColor: "rgba(255, 0, 0, 1)",
                    backgroundColor: "rgba(0,0,0,0)",
                    type: 'line',
                    cubicInterpolationMode: 'undefined'
                }]
            },
            temperatureOptions: {
                title: {
                    display: true,
                },
                scales: {
                    yAxes: [{
                        id: "y-axis-1",
                        position: "left",
                        scaleLabel: {
                            display: true,
                            labelString: 'スコア',
                        },
                        ticks: {
                            suggestedMax: 50,
                            suggestedMin: -50
                        }
                    }, {
                        id: "y-axis-2",
                        position: "right",
                        ticks: {
                            suggestedMax: 50,
                            suggestedMin: -30
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '気温',
                        },
                    }]
                }
            }
        }
    },
    methods: {
        loadDailyReportData: function () {
            window.axios.get(this.routes.displayMedicalCooperation, {
                params: {
                    person_id: this.$route.params.id,
                    register_date: this.$route.query.date
                }
            })
                .then((response) => {
                    this.dailyReports = response.data.data;
                    this.chartData(response.data.data);
                })
                .catch((error) => {

                })
        },
        loadPersonData: function () {
            window.axios.get(this.routes.person(this.$route.params.id))
                .then((response) => {
                    this.person = response.data.data;
                })
                .catch((error) => {

                })
        },
        chartData: function (dailyReports) {
            if (!this.dailyReports.length > 0) {
                this.renderChart();
                return;
            }

            let pressureData = [];
            let pulseData = [];
            let bodyTemperatureData = [];
            let daytimeData = [];
            let hpaData = [];
            let humidityData = [];
            let temperatureData = [];
            let feelingData = [];
            let date = [];

            Object.keys(dailyReports).forEach(function (key) {
                pressureData.push(dailyReports[key].attributes.pressure_max_min);
                pulseData.push(dailyReports[key].attributes.pulse);
                bodyTemperatureData.push(dailyReports[key].attributes.body_temperature);
                daytimeData.push(dailyReports[key].attributes.daytime);
                hpaData.push(dailyReports[key].attributes.pressure);
                humidityData.push(dailyReports[key].attributes.humidity);
                temperatureData.push(dailyReports[key].attributes.temperature);
                date.push(this.$moment(dailyReports[key].attributes.register_date).format('DD日'));
                feelingData.push(
                    dailyReports[key].relationships.dailyReportDetails[11][0].attributes.point
                    + dailyReports[key].relationships.dailyReportDetails[12][0].attributes.point
                    + dailyReports[key].relationships.dailyReportDetails[13][0].attributes.point
                    + dailyReports[key].relationships.dailyReportDetails[14][0].attributes.point
                    + dailyReports[key].relationships.dailyReportDetails[15][0].attributes.point
                );
            }, this);

            this.$set(this.pressureData.datasets[0], 'data', pressureData);
            this.$set(this.pressureData, 'labels', date);

            this.$set(this.pulseData.datasets[0], 'data', pulseData);
            this.$set(this.pulseData.datasets[1], 'data', bodyTemperatureData);
            this.$set(this.pulseData, 'labels', date);

            this.$set(this.daytimeData.datasets[0], 'data', feelingData);
            this.$set(this.daytimeData.datasets[1], 'data', daytimeData);
            this.$set(this.daytimeData, 'labels', date);

            this.$set(this.hpaData.datasets[0], 'data', feelingData);
            this.$set(this.hpaData.datasets[1], 'data', hpaData);
            this.$set(this.hpaData, 'labels', date);

            this.$set(this.humidityData.datasets[0], 'data', feelingData);
            this.$set(this.humidityData.datasets[1], 'data', humidityData);
            this.$set(this.humidityData, 'labels', date);

            this.$set(this.temperatureData.datasets[0], 'data', feelingData);
            this.$set(this.temperatureData.datasets[1], 'data', temperatureData);
            this.$set(this.temperatureData, 'labels', date);

            this.renderChart();
        },
        renderChart: function () {
            this.render = true;
        },
        checkDateTime: function (bed_time, wake_time, register_date, time, subtract) {
            let bedTime = this.$moment(bed_time).format('YYYY-MM-DD HH');
            let tableTime = this.$moment(register_date + ' ' + time).subtract(subtract, 'days').format('YYYY-MM-DD HH');
            let wakeTime = this.$moment(wake_time).format('YYYY-MM-DD HH');

            return bedTime < tableTime && wakeTime >= tableTime;
        },
        createTableDays: function () {
            let days = [];

            let start = this.$moment(this.$route.query.date).startOf('month');
            let end = this.$moment(this.$route.query.date).endOf('month');

            for (let day = start; day <= end; day.add(1, 'days')) {
                days.push(day.format('YYYY-MM-DD'));
            }

            return days;
        },
        floor: function (data, digit) {
            return Math.floor(data * digit) / digit;
        },
        round: function (data, digit) {
            if (data === 0) return 0;
            return Math.round(data * digit) / digit;
        },
        printReport: function () {
            window.print();
        }
    },
    computed: {
        aveTime: function () {
            return function (totalMinutes) {
                if (typeof (totalMinutes) === 'number') {
                    let hour = Math.floor(totalMinutes / 60);
                    let minute = totalMinutes % 60;

                    if (hour >= 24) {
                        hour = hour % 24;
                    }

                    return this.$moment({hour: hour, minute: minute}).format('HH:mm');
                }
            }
        },
        aveTotalSleepTime: function () {
            let sleepTime = [];

            if (this.dailyReports.length > 0) {
                Object.keys(this.dailyReports).forEach(function (key) {
                    const dateTo = this.$moment(this.dailyReports[key].attributes.bed_time);
                    const dateFrom = this.$moment(this.dailyReports[key].attributes.wake_time);
                    sleepTime.push(this.floor(dateFrom.diff(dateTo) / (60 * 1000), 10));
                }, this)

                const average = this.floor(sleepTime.reduce((prev, current) => prev + current, 0) / this.dailyReports.length, 1);

                let hour = Math.floor(average / 60);
                let minute = average % 60;

                if (hour >= 24) {
                    return hour + ':' + this.$moment({minute: minute}).format('mm');
                }

                return this.$moment({hour: hour, minute: minute}).format('HH:mm');
            } else {
                return ''
            }
        },
        sleepAmount: function () {
            return function (dailyReport) {
                const dateTo = this.$moment(dailyReport.attributes.bed_time);
                const dateFrom = this.$moment(dailyReport.attributes.wake_time);

                const totalMinutes = this.floor(dateFrom.diff(dateTo) / (60 * 1000), 10)

                let hour = Math.floor(totalMinutes / 60);
                let minute = totalMinutes % 60;

                if (hour >= 24) {
                    return hour + ':' + this.$moment({minute: minute}).format('mm');
                }

                return this.$moment({hour: hour, minute: minute}).format('HH:mm');
            }
        },
        calcTotalMinutes: function () {
            return function (prop) {
                if (this.dailyReports.length > 0) {
                    const hours = this.dailyReports.reduce((prev, current) =>
                        prev + this.hours[this.$moment(current['attributes'][prop]).format('HH')], 0);
                    const minutes = this.dailyReports.reduce((prev, current) =>
                        prev + parseInt(this.$moment(current['attributes'][prop]).format('mm')), 0);

                    const totalMinutes = hours * 60 + minutes;

                    return this.round(totalMinutes / this.dailyReports.length, 1);
                }
            }
        },
        sumFeeling: function () {
            return function (dailyReportDetails) {
                return dailyReportDetails[11][0].attributes.point
                    + dailyReportDetails[12][0].attributes.point
                    + dailyReportDetails[13][0].attributes.point
                    + dailyReportDetails[14][0].attributes.point
                    + dailyReportDetails[15][0].attributes.point
            }
        },
        displayDate: function () {
            return function (date) {
                const day = this.$moment(date).format('D日');
                const week = this.weekStr(this.$moment(date).format('e'));
                return day + week;
            }
        },
        sleepQuality: function () {
            return function (sleepQuality) {
                switch (sleepQuality) {
                    case 0:
                        return ''
                    case 1:
                        return '良い'
                    case 2:
                        return '普通'
                    case 3:
                        return '悪い'
                    default:
                        return '-'
                }
            }
        },
        diet: function () {
            return function (diet) {
                switch (diet) {
                    case 0:
                        return ''
                    case 1:
                        return '○'
                    case 2:
                        return '△'
                    case 3:
                        return '×'
                    default:
                        return '-'
                }
            }
        },
        medicine: function () {
            return function (medicine) {
                switch (medicine) {
                    case 0:
                        return ''
                    case 1:
                        return '○'
                    case 2:
                        return '×'
                    case 3:
                        return ''
                    default:
                        return '-'
                }
            }
        },
        weekStr: function () {
            return function (week) {
                switch (parseInt(week)) {
                    case 0:
                        return '(日)'
                    case 1:
                        return '(月)'
                    case 2:
                        return '(火)'
                    case 3:
                        return '(水)'
                    case 4:
                        return '(木)'
                    case 5:
                        return '(金)'
                    case 6:
                        return '(土)'
                    default:
                        return '-'
                }
            }
        },
        registerDate: function () {
            if (this.dailyReports.length > 0) {
                return this.$moment(this.dailyReports[0].attributes.register_date).format('M月');
            } else {
                return '';
            }
        },
        personName: function () {
            if (this.dailyReports.length > 0) {
                return this.dailyReports[0].relationships.person.attributes.name;
            } else {
                return '';
            }
        },
        facilityName: function () {
            if (this.dailyReports.length > 0) {
                return this.dailyReports[0].relationships.person.relationships.facility.attributes.name;
            } else {
                return '';
            }
        },
        officeAddress: function () {
            if (this.dailyReports.length > 0) {
                return this.dailyReports[0].relationships.person.relationships.facility.relationships.office.attributes.address;
            } else {
                return '';
            }
        },
        officeName: function () {
            if (this.dailyReports.length > 0) {
                return this.dailyReports[0].relationships.person.relationships.facility.relationships.office.attributes.name;
            } else {
                return '';
            }
        },
        officeCompanyName: function () {
            if (this.dailyReports.length > 0) {
                return this.dailyReports[0].relationships.person.relationships.facility.relationships.office.attributes.company_name;
            } else {
                return '';
            }
        },
        officeTel: function () {
            if (this.dailyReports.length > 0) {
                return this.dailyReports[0].relationships.person.relationships.facility.relationships.office.attributes.tel;
            } else {
                return '';
            }

        },
        ave: function () {
            return function (prop) {
                if (this.dailyReports.length > 0) {
                    const sum = this.dailyReports.reduce((prev, current) => prev + current['attributes'][prop], 0);
                    return sum / this.dailyReports.length;
                }
            }
        },
        aveFloat: function () {
            return function (prop) {
                if (this.dailyReports.length > 0) {
                    const sum = this.dailyReports.reduce((prev, current) => parseFloat(prev) + parseFloat(current['attributes'][prop]), 0);
                    return sum / this.dailyReports.length;
                }
            }
        }
    }
}
</script>

<style src="./DisplayMedicalCooperationNursingReport.scss" lang="scss" scoped/>
