<script>
import { Bar } from 'vue-chartjs';

export default {
  extends: Bar,
  name: 'chart',
  props: {
    data: {
      type: Object,
      require: false,
      default: null
    },
    options: {
      type: Object,
      require: false,
      default: null
    },
  },
  mounted () {
    this.renderChart(this.data, this.options)
  }
}
</script>

<style src="./Chart.scss" lang="scss" scoped/>

